<template>
  <div class="">
    <div :id="`box-word-transcript-${idBlock}`" class="p-5 bg-white" style="box-shadow: 0px 1px 2px 0px #888888;">
      <pre
          style=""
          class="text-subtitle-1 text-justify font-weight-medium"
          v-html="tranScriptRemake"
      />
    </div>
    <div class="d-flex align-center mt-n3">
        <span class="d-inline-flex align-center mr-3" style="font-size: 12px">
          <label style="font-size: 50px; color: lightblue">•</label>
          Acceptable
        </span>
      <span class="d-inline-flex align-center mr-3" style="font-size: 12px">
          <label style="font-size: 50px; color: gold">•</label>
          Questioning
        </span>
      <span class="d-inline-flex align-center mr-3" style="font-size: 12px">
          <label style="font-size: 50px; color: lightpink">•</label>
          Warning
        </span>

      <i class="flex-grow-1" style="font-size: 12px; text-align: right">Tip: click on word to go to recording timestamp</i>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    aiTranscript: {
      default: () => {
        return '';
      }
    },
    setCurrentTime: {
      default: () => {
        return;
      }
    },
    setTimePlay: {
      default: () => {
        return;
      }
    },
    idBlock: {
      default: () => {
        return 0;
      }
    }
  },
  name: "AutoTranscipt",
  data: function () {
    return {
      tranScriptRemake: "",
    };
  },
  mounted: function () {
    let html = '';
    (this.aiTranscript.words || []).map((word) => {
      if (word.confidence > 0.8) {
        html = `${html} <b class="word-transcript-obj word-blue" data-start_time="${word.start_time}" data-end_time="${word.end_time}">${word.punctuated_word}</b>`;
      }
      if (word.confidence > 0.6 && word.confidence <= 0.8) {
        html = `${html} <b class="word-transcript-obj word-gold" data-start_time="${word.start_time}" data-end_time="${word.end_time}">${word.punctuated_word}</b>`;
      }
      if (word.confidence <= 0.6) {
        html = `${html} <b class="word-transcript-obj word-red" data-start_time="${word.start_time}" data-end_time="${word.end_time}">${word.punctuated_word}</b>`;
      }
    });
    this.tranScriptRemake = html;

    const wordBox = document.querySelector(`#box-word-transcript-${this.idBlock}`);

    wordBox.addEventListener('click', (evt) => {
      if (evt.target.classList.contains('word-transcript-obj')){
        const elm = evt.target.closest('.word-transcript-obj');
        console.log('elm.textContent', elm.textContent);
        navigator.clipboard.writeText(elm.textContent);
        const startTime = elm.dataset.start_time;
        this.setTimePlay(startTime);
      }
    })
  },
  watch: {
    "updateData.replace": function (newVal) {
      this.replaceText = newVal;
    },
    "updateData.commentValue": function (newVal) {
      this.commentValue = newVal;
    },
  },
};
</script>

<style>
.word-transcript-obj{
  cursor: pointer;
  display: inline-block;
  padding: 0px 2px;
}
.word-transcript-obj.word-blue{
  background: #e8f5fd;
}
.word-transcript-obj.word-blue:hover{
  background: lightblue;
}
.word-transcript-obj.word-gold{
  background: #faf5d9;
}
.word-transcript-obj.word-gold:hover{
  background: #fcebb8;
}
.word-transcript-obj.word-red{
  background: #ffe7e7;
}
.word-transcript-obj.word-red:hover{
  background: lightpink;
}
</style>
