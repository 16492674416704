<template>
  <div ref="markingTicketItem">
    <v-row class="d-inline-block fix-font" style="width: 100%">
      <slot name="top"></slot>
      <!-- <v-col cols="7" class="pt-0 pb-0">
        <div class="d-flex">
          <label class="text-h6 col-2">Type question: </label>
          <p class="text-h5 deep-purple--text lighten-1 col-5">{{(answer.question_type == 5) ? 'Writing' : 'Recording'}}</p>
        </div>
      </v-col> -->

      <!-- <v-col cols="12" class="pt-0 pb-0" v-if="answer.question.exercise.element != null">
        <div class="d-flex">
          <label class="text-h6 col-2">Element Exercise: </label>
          <ElementType :element_type="answer.question.exercise.element.type" :element_data="answer.question.exercise.element.type"></ElementType>
        </div>
      </v-col> -->
      <!--      {{answer.question.section}}-->
      <v-col cols="12" class="pt-0 pb-0">
        <div class="d-flex">
          <label
              class="text-body-1 font-weight-bold col-1 fix-font"
              style="font-size: 1.1rem !important"
          >Section:
          </label>
          <div style="width: 60%">
            <p
                class="text-body-1 text-justify col-10 mb-0 fix-font"
                style="font-weight: 600"
            >
              {{ answer.question.section.title }}
            </p>
            <div style="width: 100%; clear: both">
              <ElementType
                  :element="answer.question.section.element"
              ></ElementType>
            </div>
            <div style="width: 100%; clear: both">
              <ElementType
                  :element="answer.question.section.sub_element"
              ></ElementType>
            </div>
          </div>
        </div>
      </v-col>

      <v-col cols="12" class="pt-0 pb-0">
        <div class="d-flex">
          <label
              class="text-body-1 font-weight-bold col-1"
              style="font-size: 1.1rem !important"
          >Exercise:
          </label>
          <div style="width: 100%">
            <p
                class="text-body-1 text-justify col-10 mb-0"
                style="font-weight: 600"
            >
              {{ answer.question.exercise.title }}
            </p>
            <p
                class="text-body-1 text-justify col-10 mb-0"
                v-html="answer.question.exercise.content"
            ></p>
            <ElementType
                :element="answer.question.exercise.element"
            ></ElementType>
            <ElementType
                :element="answer.question.exercise.sub_element"
            ></ElementType>
          </div>
        </div>
      </v-col>

      <v-col cols="12" class="pt-0 pb-0">
        <div class="d-flex">
          <label
              class="text-body-1 font-weight-bold col-1"
              style="font-size: 1.1rem !important"
          >Question:
          </label>
          <p
              class="text-body-1 text-justify col-10"
              v-html="answer.question.title"
          ></p>
        </div>
      </v-col>

      <v-col cols="12" class="pt-0 pb-0">
        <div class="d-flex">
          <label
              class="text-body-1 font-weight-bold col-1"
              style="font-size: 1.1rem !important"
          >Answer:
          </label>
          <div class="col-10">
            <Writing
                v-if="answer.question_type == 5"
                :answer="answer"
                :rawContent="answer.answer"
                :content="
                answer.marking_data == null
                  ? answer.answer
                  : answer.marking_data.paragraph
              "
                ref="writing"
            >
            </Writing>
            <template
                v-if="
                answer.question_type == 9 &&
                dataMarkingTicketAnswer[indexAnswer].file !== null
              "
            >

              <v-row
                  class="pl-6 pb-4 d-flex"
                  v-if="
                  dataMarkingTicketAnswer[indexAnswer].is_required_script == 1
                "
              >
                <v-col>
                  <p class="text-body-1 font-weight-bold fix-font">Auto Transcript:</p>
                  <AutoTranscipt :setTimePlay="(time) => $refs.recordingPlayer.setTimePlay(time)" :idBlock="indexAnswer"
                                 :aiTranscript="dataMarkingTicketAnswer[indexAnswer].ai_transcript || ''"/>
                </v-col>

                <v-col>
                  <p class="text-body-1 font-weight-bold fix-font">Student's Transcript:</p>
                  <Writing
                      :answer="answer"
                      :rawContent="dataMarkingTicketAnswer[indexAnswer].answer_script"
                      :content="
                      dataMarkingTicketAnswer[indexAnswer].answer_script == null
                        ? ''
                        : checkShowAnswerScript(
                            dataMarkingTicketAnswer[indexAnswer].answer_script
                          )
                    "
                      :skillId="3"
                      @actionSave="checkMarkerTrackpoint('annotate')"
                      ref="requiredScript"
                  >
                  </Writing>
                </v-col>
              </v-row>

              <Recording
                  :file="fileAnswer"
                  :dataRecording="
                  dataMarkingTicketAnswer[indexAnswer].marking_data
                "
                  @getMarkingData="setDataRecording"
                  ref="recordingPlayer"
              ></Recording>
            </template>
          </div>
        </div>
      </v-col>

      <v-col cols="12" class="pt-2" v-if="answer.question_type == 5">
        <div class="d-flex">
          <label
              class="mt-4 text-body-1 font-weight-bold col-1 fix-font"
              style="font-size: 1.1rem !important"
          >Marking Criteria:
          </label>
          <div class="col-10">
            <table class="table" style="border: 1px dashed #cdcdcd">
              <thead class="thead-ipp">
              <tr>
                <th class="text-button fix-font" scope="col">Criteria</th>
                <th class="text-button fix-font" scope="col">Score</th>
                <!--                <th class="text-button fix-font" scope="col">Comment</th>-->
              </tr>
              </thead>
              <tbody>
              <template v-for="(detail, iDetail) in detailAnswer">
                <!--                Phần nhập điểm và nhận xét criteria -->
                <tr :key="iDetail">
                  <td
                      class="text-body-1 text-justify font-weight-medium fix-font"
                      style="width: 330px"
                  >
                    <p class="text-h6 text-justify mb-5 p-3">
                      {{ detail.criteria_name }}
                    </p>
                  </td>
                  <td>
                    <div style="width: 100%" class="d-flex">
                      <v-text-field
                          class="text-body-1"
                          type="number"
                          :max="calculateMethod.max_score"
                          maxlength="1"
                          solo
                          dense
                          style="width: 8%"
                          readonly
                          oninput="this.value=this.value.slice(0,this.maxLength||1/1);this.value=(this.value   < 0) ? (0/0) : this.value;"
                          :value="detail.score"
                          @blur="checkMarkerTrackpoint('score')"
                          @input="changeValueSroceAnswerDetail($event, iDetail)"
                      ></v-text-field>
                      <v-textarea
                          solo
                          auto-grow
                          dense
                          rows="1"
                          class="ml-1 text-body-1 fix-font"
                          style="width: 90%"
                          :value="detail.comment"
                          @blur="checkMarkerTrackpoint('remark')"
                          @input="
                            changeValueCommentAnswerDetail($event, iDetail)
                          "
                      ></v-textarea>
                    </div>
                  </td>
                </tr>

                <!--                Phần nhập dữ liệu sub-criteria-->
                <tr :key="'item_sub-' + iDetail">
                  <td colspan="2">
                    <SelectSubCriteria
                        @setDescriptorSubCriteria="changeValueSubCriterial"
                        :iAnswer="indexAnswer"
                        :iDetail="iDetail"
                        :sub_criteria_ticket="detail.sub_criteria"
                    />
                  </td>
                </tr>
              </template>
              <tr>
                <td
                    class="text-body-1 text-right font-weight-medium"
                    style="
                      font-size: 1.1rem !important;
                      color: black;
                      padding-top: 1.2em;
                    "
                >
                  Overall Score
                </td>
                <td style="max-width: 15px" colspan="2">
                  <v-text-field
                      type="number"
                      class="score-over text-body-1"
                      v-model="scoreAnswer"
                      solo
                      dense
                      @blur="checkMarkerTrackpoint('score')"
                      readonly
                  ></v-text-field>
                </td>
                <!--                <td>
                                <v-textarea class="comment-over text-body-1" solo auto-grow dense rows="1"
                                            v-model="commentAnswer"></v-textarea>
                              </td>-->
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </v-col>

      <v-col cols="12" class="pt-2" v-if="answer.question_type == 5">
        <div class="d-flex">
          <label
              class="mt-4 text-body-1 font-weight-bold col-1"
              style="font-size: 1.1rem !important"
          >Action Plan:
          </label>
          <div class="col-10">
            <label class="font-weight-bold fix-font">General Comments</label>
            <v-textarea
                class="comment-over text-body-1"
                solo
                auto-grow
                dense
                rows="5"
                @blur="checkMarkerTrackpoint('plan')"
                v-model="general_comment"
            ></v-textarea>

            <div v-for="(item, index) in action_plans" :key="index">
              <div
                  class="position-relative mt-2 overflow-hidden"
                  :class="`action-plan-item-${index}`"
              >
                <label
                >{{ `Action ${index + 1}` }}
                  <span v-if="index == 0">(Required)</span></label
                >
                <v-textarea
                    class="comment-over text-body-1"
                    filled
                    auto-grow
                    v-model="item.action"
                    :placeholder="`Action ${index + 1}`"
                    @blur="checkMarkerTrackpoint('plan')"
                    @keydown="_updateActionPlanToStore()"
                    @keyup="_updateActionPlanToStore()"
                    :class="{ 'mr-50': item.allowDelete }"
                ></v-textarea>

                <button
                    v-if="item.allowDelete"
                    class="position-absolute"
                    style="
                    top: 24px;
                    bottom: 29px;
                    right: 1px;
                    font-weight: bold;
                    background: #ff4b4b;
                    color: white;
                    width: 50px;
                    border-bottom-right-radius: 5px;
                    border-top-right-radius: 5px;
                  "
                    @click="_removeAction(index)"
                >
                  Delete
                </button>
              </div>
              <div
                  style="color: #ff4b4b"
                  :class="`action-plan-item-error-${index}`"
              ></div>
            </div>

            <button
                class="btn btn-warning mr-2 mt-2 mx-auto"
                @click="_addMoreAction()"
            >
              Add Action
            </button>
          </div>
        </div>
      </v-col>

      <!-- <v-col cols="12" class="mt-0 pt-0" v-if="answer.question_type == 5">
        <div class="d-flex">
          <label style="margin-top: -130px; font-size: 1.1rem !important" class="text-body-1 font-weight-medium col-1">Overall Score: </label> -->
      <!-- <div class="col-10 d-flex">
        <div class="col-5"></div>
        <div class="d-flex col-2">
          <label class="text-button font-weight-medium mt-1">Score: </label>
          <b-form-input class="" style="max-width: 70px" type="number" v-model="scoreAnswer"></b-form-input>
        </div>
        <div class="d-flex col-5">
          <label class="text-button font-weight-medium mt-1">Comment: </label>
          <b-form-input class="" v-model="commentAnswer"></b-form-input>
        </div>
      </div> -->
      <!-- </div>
    </v-col> -->
    </v-row>
  </div>
</template>

<script>
import {mapState} from "vuex";
import marking_trackpoint from "./mixins/marking-trackpoint";
// import {supportTool} from "./mixins/supportTool";
import SelectSubCriteria from "./tools/SelectSubCriteria";
import AutoTranscipt from "@/components/marking/AutoTranscript.vue";

export default {
  name: "MarkingTicketQuestionDetail",
  mixins: [marking_trackpoint],
  data: function () {
    return {
      action_plans: [],
      general_comment: "",
    };
  },
  components: {
    AutoTranscipt,
    //OldWriting: () => import("@/components/marking/OldWriting.vue"),
    Writing: () => import("@/components/marking/Writing2.vue"),
    // ElementType: () => import('@/components/tests/Form/display/ElementType.vue'),
    Recording: () => import("./MarkingForRecording.vue"),
    ElementType: () => import("./DisplayElement/ElementType.vue"),
    SelectSubCriteria,
  },
  props: {
    answer: {
      type: Object,
      default: function () {
        return {};
      },
    },
    calculateMethod: {
      type: Object,
      default: function () {
        return {};
      },
    },
    indexAnswer: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapState({
      dataMarkingTicketAnswer: (state) =>
          state.markingTicketAnswerStore.dataMarkingTicketAnswer,
    }),
    scoreAnswer: {
      get() {
        return this.dataMarkingTicketAnswer[this.indexAnswer].score;
      },
      set(value) {
        this.$store.commit("markingTicketAnswerStore/setScoreAnswer", {
          value: value,
          index: this.indexAnswer,
        });
      },
    },
    commentAnswer: {
      get() {
        return this.dataMarkingTicketAnswer[this.indexAnswer].comment;
      },
      set(value) {
        this.$store.commit("markingTicketAnswerStore/setCommentAnswer", {
          value: value,
          index: this.indexAnswer,
        });
      },
    },
    detailAnswer: {
      get() {
        let data = this.dataMarkingTicketAnswer[this.indexAnswer].details;
        return data;
      },
    },
    fileAnswer: {
      get() {
        return this.dataMarkingTicketAnswer[this.indexAnswer].file.url;
      },
    },
  },
  watch: {
    general_comment() {
      this._updateGeneralCommentToStore();
    },
  },
  created() {
    this.setStoreDataMarkingTicketAnswer(this.answer, this.indexAnswer);
  },
  methods: {
    _addMoreAction(allowDelete = true, required = false) {
      this.action_plans.push({
        action: "",
        allowDelete: allowDelete,
        required: required,
      });
    },
    _removeAction(index) {
      const action_plans = JSON.parse(JSON.stringify(this.action_plans));
      action_plans.splice(index, 1);
      this.action_plans = action_plans;
      this._updateActionPlanToStore();
    },
    _updateSize(event) {
      event.target.style.height = "auto";
      event.target.style.height = event.target.scrollHeight + "px";
    },
    _updateActionPlanToStore() {
      let action_plans = this.action_plans.filter((item) => item.action);
      action_plans = action_plans.map((item) => item.action);
      this.$store.commit("markingTicketAnswerStore/setActionPlans", {
        action_plans: action_plans,
        index: this.indexAnswer,
      });
    },
    _updateGeneralCommentToStore() {
      this.$store.commit("markingTicketAnswerStore/setGeneralComment", {
        general_comment: this.general_comment,
        index: this.indexAnswer,
      });
    },
    setStoreDataMarkingTicketAnswer(answer, index) {
      this.$store.commit(
          "markingTicketAnswerStore/SET_DATA_MARKING_TICKET_ANSWER",
          {answer, index}
      );
    },
    changeValueSroceAnswerDetail(value, i) {
      this.caculateOverScore(value, i);
      this.$store.commit("markingTicketAnswerStore/setScoreDetailAnswer", {
        value: value,
        indexDetail: i,
        indexAnswer: this.indexAnswer,
      });
    },
    changeValueCommentAnswerDetail(value, i) {
      this.$store.commit("markingTicketAnswerStore/setCommentDetailAnswer", {
        value: value,
        indexDetail: i,
        indexAnswer: this.indexAnswer,
      });
    },
    getOutputDataWriting(purpose = "submit") {
      console.log(purpose);
      /*      if (purpose == 'submit') {
              if (this.$refs.markingTicketItem.querySelector('.action-plan-item-error-0')) {
                this.$refs.markingTicketItem.querySelector('.action-plan-item-error-0').innerHTML = ``;
              }
              if (this.$refs.markingTicketItem.querySelector('.action-plan-item-error-1')) {
                this.$refs.markingTicketItem.querySelector('.action-plan-item-error-1').innerHTML = ``;
              }
              if (!this?.action_plans[0]?.action) {
                if (this.$refs.markingTicketItem.querySelector('.action-plan-item-0 textarea')) {
                  this.$refs.markingTicketItem.querySelector('.action-plan-item-0 textarea').focus();
                }
                if (this.$refs.markingTicketItem.querySelector('.action-plan-item-error-0')) {
                  this.$refs.markingTicketItem.querySelector('.action-plan-item-error-0').innerHTML = `Action plan 1 là Required`;
                }
                return;
              }
            }*/
      return this.$refs.writing.getOutputData();
    },
    setDataRecording(val) {
      this.$store.commit("markingTicketAnswerStore/setDataMarkingRecording", {
        value: val,
        indexAnswer: this.indexAnswer,
      });
    },
    caculateOverScore(scoreDetail, indexScore) {
      let detailAnswer = this.detailAnswer;
      let totalScore = 0;
      let count = 0;
      for (let i = 0; i < detailAnswer.length; i++) {
        detailAnswer[i].score =
            detailAnswer[i].score == null ? 0 : detailAnswer[i].score;
        if (i == indexScore) {
          detailAnswer[i].score = scoreDetail;
        }
        totalScore += parseInt(detailAnswer[i].score);
        count++;
        if (isNaN(totalScore)) return;
      }
      let scoreOver = totalScore / count;
      if (this.calculateMethod.marking_criteria_calculate_method == 1) {
        let rounddown = Math.floor(parseInt(totalScore) / 2) / 2;
        this.$store.commit("markingTicketAnswerStore/setScoreAnswer", {
          value: rounddown,
          index: this.indexAnswer,
        });
      } else {
        this.$store.commit("markingTicketAnswerStore/setScoreAnswer", {
          value: scoreOver,
          index: this.indexAnswer,
        });
      }
    },
    getOutputDataRequiredScript() {
      return this.$refs.requiredScript.getOutputData();
    },
    convertDataScript(dataScript) {
      let checkJson = this.$utils.IsJsonString(dataScript);
      let string = checkJson ? JSON.parse(dataScript).paragraph : dataScript;
      return string;
    },
    checkShowAnswerScript(answer_script) {
      let type = typeof answer_script;
      return type === "object" ? answer_script.paragraph : answer_script;
    },
    changeValueSubCriterial(value, indexSubCriterial, indexDetail) {
      this.$store.commit(
          "markingTicketAnswerStore/setDescriptorSubCriteriaAnswer",
          {
            value: value,
            indexDetail: indexDetail,
            indexAnswer: this.indexAnswer,
            indexSubCriteria: indexSubCriterial,
          }
      );

      let detail = this.detailAnswer[indexDetail];
      // let scoreDetail = detail.score;
      let scoreByValDescriptor = detail.sub_criteria[
          indexSubCriterial
          ].descriptors.filter((e) => e.descriptor_id === value)[0].score;
      let commentByValDescriptor = detail.sub_criteria[
          indexSubCriterial
          ].descriptors.filter((e) => e.descriptor_id === value)[0].name;

      this.$store.commit("markingTicketAnswerStore/setScoreSubCriteriaAnswer", {
        value: scoreByValDescriptor,
        indexDetail: indexDetail,
        indexAnswer: this.indexAnswer,
        indexSubCriteria: indexSubCriterial,
      });

      this.$store.commit(
          "markingTicketAnswerStore/setCommentSubCriteriaAnswer",
          {
            value: commentByValDescriptor,
            indexDetail: indexDetail,
            indexAnswer: this.indexAnswer,
            indexSubCriteria: indexSubCriterial,
          }
      );

      let data_score_sub_cateria = this.detailAnswer[indexDetail].sub_criteria
          .filter((sub_criteria) => {
            return (
                sub_criteria.score != null &&
                sub_criteria.score !== 0 &&
                sub_criteria.score !== ""
            );
          })
          .map((sub_criteria) => sub_criteria.score);

      if (data_score_sub_cateria.length > 0) {
        let min_score_sub_cateria = Math.min(...data_score_sub_cateria);
        this.changeValueSroceAnswerDetail(min_score_sub_cateria, indexDetail);
      }
    },
  },
  mounted() {
    this.general_comment = this.answer.action_plan?.general_comment;
    if (!this.general_comment && this.answer?.comment) {
      this.general_comment = this.answer.comment;
    }
    if (
        !this.answer.action_plan ||
        this.answer.action_plan.actions == undefined ||
        this.answer.action_plan.actions.length == 0
    ) {
      this._addMoreAction(false, true);
      this._addMoreAction(true, false);
    } else {
      const action_plans = this.answer.action_plan.actions;
      this.general_comment = this.answer.action_plan.general_comment;
      this.$store.commit("markingTicketAnswerStore/setActionPlans", {
        action_plans: action_plans,
        index: this.indexAnswer,
      });
      for (let i = 0; i < action_plans.length; i++) {
        this.action_plans.push({
          action: action_plans[i],
          allowDelete: i == 0 ? false : true,
          required: i == 0 ? true : false,
        });
      }
    }
  },
};
</script>

<style lang="css" scoped>
.text-body-1 {
  font-family: "Montserrat", sans-serif !important;
}

.fix-font {
  font-family: "Montserrat", sans-serif !important;
}

.border-score {
  border: 2px solid black;
}

.score-over.v-text-field--solo > .v-input__control > .v-input__slot {
  border: 2px solid;
}

.comment-over.v-text-field--solo > .v-input__control > .v-input__slot {
  border: 2px solid;
}

.mr-50 {
  margin-right: 50px;
}
</style>
